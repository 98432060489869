import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import RFAForm from '../../RFA/RFAForm';

type Props = {
  Title?: React.FC;
  SubTitle?: string;
};

const Hero: React.FC<Props> = ({ Title, SubTitle }) => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "solvana-solar-roof-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={classes.root}>
      <div className={classes.topContainer}>
        <Img className={classes.img} fluid={data.image.childImageSharp.fluid} />
        <hgroup className={classes.title}>
          <Typography color="primary" variant="h1">
            {Title ? (
              <Title />
            ) : (
              <>
                Smart.
                <br />
                Fast.
                <br />
                Easy.
                <br />
              </>
            )}
          </Typography>
          <Typography className={classes.subtitle} color="primary" variant="h2">
            {SubTitle ? (
              SubTitle
            ) : (
              <>
                Solar for your <br />
                home &amp; business.
              </>
            )}
          </Typography>
        </hgroup>
        <Typography className={classes.titleMobile} color="primary" variant="h1">
          {Title ? <Title /> : 'Smart. Fast. Easy.'}
        </Typography>
      </div>
      <div className={classes.bottomContainer}>
        <div className={classes.formContainer}>
          <Typography variant="h4">Get your custom solar proposal in less than 48 hours</Typography>
          <RFAForm />
        </div>
      </div>
    </div>
  );
};

export default Hero;
