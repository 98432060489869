import React from 'react';
import Layout from '../containers/Layout';
import Hero from '../containers/LandingPages/Hero';

const IndexPage: React.FC = () => (
  <Layout
    title="Solvana | Buy solar online for your home or business"
    withoutMarginTop
    withTransparentHeaderBg
    hideHeaderLinks
  >
    <Hero
      Title={() => (
        <>
          Buy Solar
          <br />
          100% Online
        </>
      )}
      SubTitle="Get your solar proposal in 48 hrs"
    />
  </Layout>
);

export default IndexPage;
