import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors, isDarkMode, linearPrimaryGradient } from '../../../theme/Colors';

const BG_SKY = '#c0def0';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 0, 0),
      boxSizing: 'border-box',
      backgroundColor: BG_SKY,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(10),
      },
    },
    img: {
      // @ts-ignore
      position: 'absolute !important',
      bottom: '0 !important',
      width: '100%',
    },
    title: {
      position: 'absolute',
      top: '15%',
      maxWidth: '428px',
      paddingLeft: theme.spacing(8),
      [theme.breakpoints.down('md')]: {
        top: '0',
      },
      '& h1': {
        fontSize: '68px',
        lineHeight: '68px',
        marginBottom: theme.spacing(1),
        background: linearPrimaryGradient,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        [theme.breakpoints.down('md')]: {
          fontSize: '48px',
          lineHeight: '48px',
          marginBottom: 0,
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none',
          fontSize: '32px',
          lineHeight: '32px',
        },
      },
      '& h2': {
        fontSize: '28px',
        lineHeight: '28px',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    topContainer: {
      width: '100%',
      height: '93vh',
      padding: theme.spacing(8, 0, 0),
      position: 'relative',
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        paddingRight: 0,
        height: '60vh',
        padding: theme.spacing(12, 0, 0),
      },
      [theme.breakpoints.down('sm')]: {
        height: '34vh',
        marginTop: theme.spacing(-3),
      },
    },
    formContainer: {
      padding: theme.spacing(3, 3, 0),
      zIndex: 2,
      marginTop: '-740px',
      borderRadius: theme.spacing(1),
      boxShadow: '0px 2px 2px -3px rgba(0,0,0,0.3), 0px 4px 6px 1px rgba(0,0,0,0.14), 0px 2px 8px 2px rgba(0,0,0,0.12)',
      width: '640px',
      backgroundColor: isDarkMode(theme) ? Colors.Dark : theme.palette.background.paper,
      '& h4': {
        textAlign: 'center',
        margin: theme.spacing(0, 'auto', 2),
        maxWidth: '480px',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '-16%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: theme.spacing(-4),
      },
    },
    bottomContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: Colors.SecondaryDark,
      padding: theme.spacing(0, 3, 0),
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 3, 6),
      },
    },
    subtitle: {
      marginTop: theme.spacing(3),
      lineHeight: 1.2,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    titleMobile: {
      display: 'none',
      fontSize: '32px',
      background: linearPrimaryGradient,
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      margin: 0,
      padding: theme.spacing(0, 3, 0),
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: '2%',
        display: 'unset',
      },
    },
  }),
);
